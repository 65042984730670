function formLoader(container, active, submitBtn = null) {
	const pulseTemplate = $(`<div class="form-loader">
		<div class="pulse"></div>
	</div>`)

	if (container) {
		const formLoaderContainer = $(container).find('.form-loader')

		if (formLoaderContainer.length <= 0) {
			if (active) {
				$(pulseTemplate).addClass('active')
			} else {
				$(pulseTemplate).removeClass('active')
			}

			$(container).append(pulseTemplate)
		} else {
			if (active) {
				$(formLoaderContainer).addClass('active')
			} else {
				$(formLoaderContainer).removeClass('active')
			}
		}

		formLoaderBtn(submitBtn, active)
	}
}

function formLoaderBtn(submitBtn, active) {
	if (submitBtn) {
		if (active) {
			$(submitBtn).attr('disabled', 'disabled').addClass('inactive')
		} else {
			$(submitBtn).removeAttr('disabled').removeClass('inactive')
		}
	}
}

export { formLoader, formLoaderBtn }
