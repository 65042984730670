// import _ from 'lodash'

//Alertify
import 'alertifyjs/build/css/alertify.min.css'
import 'alertifyjs/build/css/themes/bootstrap.css'
import alertify from 'alertifyjs/build/alertify'
alertify.set('notifier', 'delay', 10)

//Fontawesome
import '@fortawesome/fontawesome-free/js/all'

//Fancybox
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

import 'lazysizes'

//Estilos
import './styles/index.scss'
import './styles/movil.scss'

import { formLoader } from './utils'

/* PRELOAD */
if (__PRELOAD) {
	$('#status').fadeOut()
	$('#preloader').delay(550).fadeOut('slow')
}

/* scroll */
function onScroll(event) {
	var header = 100
	var scrollPos = $(document).scrollTop()

	$('section').each(function () {
		console.log(header)
		var id = '#' + $(this).attr('id')

		if (
			$(this).offset().top - header <= scrollPos &&
			$(this).offset().top + $(this).outerHeight() - header > scrollPos
		) {
			$('.menu ul li a').removeClass('activo')
			$('.menu ul li a[href="' + id + '"]').addClass('activo')
		}
	})
}

/* scroll */
function efectoScroll() {
	var items = $('.efecto, .no-efecto')
	var hw = $(window).height()
	var wTop = $(window).scrollTop()
	var screenH = wTop + hw
	$.each(items, function (inx, val) {
		var yPos = $(this).offset().top
		if (yPos <= screenH) {
			$(this).addClass('no-efecto')
			$(this).removeClass('efecto')
		} else {
			$(this).removeClass('no-efecto')
			$(this).addClass('efecto')
		}
	})
}

/* acordeon */
function acordeon() {
	var id = $(this).parent('.acordeon').attr('id')

	if ($(this).hasClass('activo')) {
		$(this).removeClass('activo')
		$(this).next().slideUp()
		$(this).next('.content').removeClass('activo')
	} else {
		$('#' + id + ' .item-acordeon').removeClass('activo')
		$(this).addClass('activo')
		$('#' + id + ' .content').slideUp()
		$(this).next().slideDown()
		$(this).next().addClass('activo')
	}
}

function efecto_gris(div_efecto) {
	var div_efecto
	$(div_efecto).on('mouseover', function (e) {
		$(div_efecto).addClass('no-activo')
		$(this).removeClass('no-activo')
	})
	$(div_efecto).on('mouseout', function (e) {
		$(div_efecto).removeClass('no-activo')
		$(this).removeClass('no-activo')
	})
}

var data_search
$(function () {
	/* detectar dispositivo */
	if (
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
	) {
		$('body').addClass('mobile')
	}

	/* base js */
	var w_body = $(window).width()
	var h_body = $(window).height()
	var cont = $('.container').width()
	var cont_left = $('.container').offset()
	var cont_margin = cont_left.left
	var h_header = $('#header').outerHeight()
	var h_footer = $('#footer').outerHeight()
	var m_cont = 15
	var res = 1140
	var header = h_header
	// console.log('gral' + header)

	/* paddins left right */
	$('.p-left').css('padding-left', cont_margin + m_cont)
	$('.p-right').css('padding-right', cont_margin + m_cont)
	/* margins left right */
	$('.m-left').css('margin-left', cont_margin + m_cont)
	$('.m-right').css('margin-right', cont_margin + m_cont)
	/* absolute left right */
	$('.a-left').css('left', cont_margin + m_cont)
	$('.a-right').css('right', cont_margin + m_cont)

	/* full */
	$('.full-height').css('min-height', h_body)

	/*$('#main').css('padding-top', h_header);*/
	$('#cont').css('padding-bottom', h_footer)

	/* btn compartir */
	$('.compartir').on('click', function (e) {
		$(this).next('section').toggleClass('activo')
	})

	/* scroll */
	$(window).on('scroll', function () {
		if ($(window).width() > res) {
			if ($(window).scrollTop() > 20) {
				$('body').addClass('scroll')
			} else {
				$('body').removeClass('scroll')
			}
		}
	})

	/* cambiar estilos textos cargados en admin ------------------------------------- */

	//$('article h1').contents().unwrap().wrap('<h2>');
	$('article h4 strong span').contents().unwrap().wrap('')
	$('.cabezal h3 p').contents().unwrap().wrap('')
	$(
		'article span, article strong, article h2, article h3, article h4, article h5, article h6, article p'
	).removeAttr('style')

	$('span, strong, p').removeAttr('style')

	/* fancybox */
	Fancybox.bind('[data-fancybox]', {
		showClass: true,
		closeButton: 'outside',
		hideScrollbar: true,
	})

	var btn_fancybox = null
	$('.btn-fancybox').on('click', function () {
		var src = this.dataset.src
		var redirect = this.dataset.redirect
		$('.redirect-to').val(redirect)

		if (btn_fancybox) {
			btn_fancybox.destroy()
		}
		btn_fancybox = Fancybox.show([{ src: src, type: 'inline' }])
		btn_fancybox.on('destroy', (fancybox) => {
			fancybox.items.forEach(function (item) {
				//Restablecer formulario de registro
				if (item.src == '#crear') {
					$(item.src).find('form#add_user_form')[0].reset()
				}
			})
		})
	})

	$('a[href^="#"]').on('click', function (e) {
		e.preventDefault()

		const href = this.href.split('#')[1]

		console.log('click', href)
		$('html, body').animate({ scrollTop: $(`#${href}`).offset().top - (header - 50) }, 500)
	})

	/* abre solo */
	$('#btn-popup').trigger('click')

	/* menú ------------------------------------------------ */
	$('.btn-menu').on('click', function (e) {
		e.preventDefault()
		$('body').toggleClass('visible')
	})

	$('.visible .menu').css('height', h_body)
	if ($(window).width() < 1140) {
		$('.menu').css('top', h_header)
	}
	/*
	$('.menu').on('click', function (e) {
		e.stopPropagation()

		if ($(window).width() < res) {
			if ($('body').hasClass('visible')) {
				$('body').removeClass('visible')
			}
		}
	})
	*/

	/* buscador ------------------------------------------ */
	$('.btn--buscador').on('click', function (e) {
		$(this).toggleClass('activo')
		$(this).prev('form').toggleClass('activo')
	})

	/* menú - submenu */

	if ($(window).width() > 1141) {
		$('.over--submenu').on('mouseover', function (e) {
			e.preventDefault()

			$('#header').addClass('open--submenu')
		})
		$('.over--submenu').on('mouseout', function (e) {
			e.preventDefault()

			$('#header').removeClass('open--submenu')
			$('.sub-menu').removeClass('visible--submenu')
		})

		$('#btn--sub-menu').on('click', function (e) {
			e.preventDefault()
			$(this).toggleClass('activo')
			$('#header').toggleClass('open--submenu')
			$('.sub-menu').toggleClass('visible--submenu')
		})
	} else {
		$('.over--submenu a').on('click', function (e) {
			e.preventDefault()
			$(this).next('.sub-menu').toggleClass('visible--submenu')
		})

		$('.menu a[href^="#"]').on('click', function (e) {
			//e.stopPropagation();

			if ($('body').hasClass('visible')) {
				$('body').removeClass('visible')
			}
		})
	}

	/* acordeones */
	$('.item-acordeon').on('click', acordeon)

	/* resize */
	$(window).on('resize', function () {
		/* base js */
		var w_body = $(window).width()
		var h_body = $(window).height()
		var cont = $('.container').width()
		var cont_left = $('.container').offset()
		var cont_margin = cont_left.left
		var h_header = $('#header').outerHeight()
		var h_footer = $('#footer').outerHeight()
		var m_cont = 15
		var res = 1141
		var header = h_header
		// console.log('reisze' + header)

		/* paddins left right */
		$('.p-left').css('padding-left', cont_margin + m_cont)
		$('.p-right').css('padding-right', cont_margin + m_cont)
		/* margins left right */
		$('.m-left').css('margin-left', cont_margin + m_cont)
		$('.m-right').css('margin-right', cont_margin + m_cont)
		/* absolute left right */
		$('.a-left').css('left', cont_margin + m_cont)
		$('.a-right').css('right', cont_margin + m_cont)

		/* full */
		$('.full-height').css('min-height', h_body)

		/*$('#main').css('padding-top', h_header);*/
		$('#cont').css('padding-bottom', h_footer)
	})

	$('figure.perfil').on('click', function (event) {
		if (!$(this).hasClass('active')) {
			$('.perfil').addClass('active')
		} else {
			$('.perfil').removeClass('active')
		}
	})

	$('#login_form, #login_form_carrito').on('submit', function (event) {
		event.preventDefault()

		const formContainer = this
		const formSubmitBtn = $(this).find('button.submit')
		formLoader(formContainer, true, formSubmitBtn)

		let data = $(this).serialize()

		var redirect_to = this.redirect_to.value ? this.redirect_to.value : 'inicio'

		// alert(redirect_to);

		$.post(
			'check-user',
			data,
			function (data, textStatus, xhr) {
				// $(this).find('#pass').focus();

				if (data) {
					if (data.success) {
						location.href = redirect_to
					} else {
						alertify.error(data.msg)
						$('#pass').val('')
						$('#pass').focus()
					}
				} else {
				}
			},
			'json'
		).always(function () {
			formLoader(formContainer, false, formSubmitBtn)
		})
	})

	$('#add_user_form').on('submit', function (event) {
		const _this = this
		event.preventDefault()

		const formContainer = this
		const formSubmitBtn = $(this).find('button.submit')
		formLoader(formContainer, true, formSubmitBtn)

		let data = $(this).serialize()

		var redirect_to = this.redirect_to.value ? this.redirect_to.value : 'inicio'
		// alert(redirect_to);

		$.post(
			'add-user',
			data,
			function (data, textStatus, xhr) {
				// $(this).find('#pass').focus();

				if (data) {
					if (data.success) {
						alertify.success(data.msg)
						location.href = redirect_to
					} else {
						alertify.error(data.msg)
						// $(_this).find('input[name="password"]').val('')
						// $(_this).find('input[name="password"]').focus()
					}
				} else {
				}
			},
			'json'
		).always(function () {
			formLoader(formContainer, false, formSubmitBtn)
		})
	})

	$('#forgot_password_form').on('submit', function (event) {
		event.preventDefault()

		const formContainer = this
		const formSubmitBtn = $(this).find('button.submit')
		formLoader(formContainer, true, formSubmitBtn)

		let data = $(this).serialize()
		$.post(
			'forgot-password',
			data,
			function (data, textStatus, xhr) {
				// $(this).find('#pass').focus();

				if (data) {
					if (data.success) {
						alertify.success(data.msg)
					} else {
						alertify.error(data.msg)
					}
				} else {
				}
			},
			'json'
		).always(function () {
			formLoader(formContainer, false, formSubmitBtn)
		})
	})

	var view = $('input[name="view"]')
	if (view && view.length) {
		let product_id = view.val()
		var fancybox_item = $('[data-fancybox][data-id="' + product_id + '"]')
		if (fancybox_item && fancybox_item.first()) {
			fancybox_item.first().trigger('click')
		}
	}

	$('#form--descarga-pdf').bind('submit', function () {
		// Capturamnos el boton de envío
		var btnEnviar = $('#submit')

		$.ajax({
			type: $(this).attr('method'),
			url: 'https://www.musicadelatierra.org/inicio',
			data: $(this).serialize(),

			beforeSend: function () {
				btnEnviar.addClass('enviando')
				btnEnviar.html('Procesando...') // Para input de tipo button
				btnEnviar.attr('disabled', 'disabled')
			},
			complete: function (data) {
				btnEnviar.removeClass('enviando')
				btnEnviar.html('Enviar')
			},
			success: function (data) {
				//$('[data-modal="modal--msj"]').trigger('click');
				//$("#msj").addClass('ok');
				//$("#msj").html('Mensaje enviado con éxito.');
				btnEnviar.removeAttr('disabled')

				var url =
					'https://www.musicadelatierra.org/data/Musica_de_la_Tierra_reporte_2024.pdf'
				window.open(url, '_blank')

				$('#form--descarga-pdf')[0].reset()

				setTimeout(function () {
					Fancybox.close()
				}, 4000)
			},
			error: function (data) {
				//$('[data-modal="modal--msj"]').trigger('click');
				$('#msj').addClass('error')
				$('#msj').html('Ha ocurrio un error.')
				$('#form--descarga-pdf')[0].reset()

				setTimeout(function () {
					Fancybox.close()
				}, 4000)

				console.log(data)
			},
		})

		// Nos permite cancelar el envio del formulario
		return false
	})
})
